/* code */
pre,
code {
	font-size: 15px;
	border: 1px solid #ddd;
	border-radius: 3px;
	background-color: #eee;
}

code {
	padding: 1px 5px;
}

pre {
	padding: 8px 12px;
	overflow-x: scroll;

	> code {
		border: 0;
		padding-right: 0;
		padding-left: 0;
		background: none;
	}
}

/* error */
.screen-reader-response {
  display: none;
}
.wpcf7-validation-errors,
.wpcf7-not-valid-tip {
  border: none;
  display: block;
  color: #f00;
  font-size: 11px;
}

/* success */
.wpcf7-form.sent .form-header,
.wpcf7-form.sent .form-content {
  display: none;
}
.wpcf7-mail-sent-ok {
  border: none;
}

/* loading */
/* 送信ボタンの位置がずれるので display none で対応 */
div.wpcf7 .ajax-loader {
  display: none !important;
}
div.wpcf7 .ajax-loader.is-active {
  display: inline-block !important;
}

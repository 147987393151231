/* marker */
.marker-red {
  font-weight: bold;
  background: linear-gradient(transparent 40%, rgba(255,170,170,0.5) 40%);
}
.marker-pink {
  font-weight: bold;
  background: linear-gradient(transparent 40%, rgba(255,153,255,0.5) 40%);
}
.marker-yellow {
  font-weight: bold;
  background: linear-gradient(transparent 40%, rgba(255,255,102,0.5) 40%);
}
.marker-green {
  font-weight: bold;
  background: linear-gradient(transparent 40%, rgba(102,255,204,0.5) 40%);
}
.marker-blue {
  font-weight: bold;
  background: linear-gradient(transparent 40%, rgba(102,204,255,0.5) 40%);
}
#wp-calendar {
  width: 100%;
  margin: 0 0 20px 0;
  padding: 10px;
  text-align: center;
  background: rgba(255,255,255,0.15);
}
#wp-calendar caption {
  padding-top: 0;
  padding-bottom: 5px;
  margin: 0 0 5px 0;
  text-align: center;
  color: inherit;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid rgba(186,186,186,0.5);
}
#wp-calendar caption a {
}
#wp-calendar table {
  width: 100%;
}
#wp-calendar thead th {
  padding-top: 5px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}
#wp-calendar tbody td {
  font-size: 12px;
  text-align: center;
}
#wp-calendar thead th:first-child,
#wp-calendar tbody td:first-child {
  color: #a00;
}
#wp-calendar thead th:last-child,
#wp-calendar tbody td:last-child {
  color: #00a;
}
#wp-calendar tbody td a {
  font-weight: bold;
  text-decoration: underline;
}
#wp-calendar tbody td a:hover {
  text-decoration: none;
}

.simple-accordion {
  ul {
    display: none;
  }

  .simple-accordion-list {
  }
  .simple-accordion-list-item {
  }
  .simple-accordion-anchor {
    display: block;
    position: relative;
    cursor: pointer;
  }
  .simple-accordion-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -0.5rem;

    &::after {
      content: "+";
    }
  }

  .is-open {
    > .simple-accordion-list {
      display: block;
    }

    > .simple-accordion-anchor > .simple-accordion-btn::after {
      content: "-";
    }
  }
}

.simple-accordion-is-animation {
  ul {
    display: none;
  }

  .simple-accordion-list {
  }
  .simple-accordion-list-item {
  }
  .simple-accordion-anchor {
    display: block;
    position: relative;
    cursor: pointer;
  }
  .simple-accordion-btn {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    right: 10px;

    &::before ,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 2px;
      background: #444;
      transition: all 0.3s ease;
    }
    &::after {
      opacity: 1;
      transform: rotate(90deg);
    }
  }

  .is-open {
    > .simple-accordion-list {
      display: block;
    }
    > .simple-accordion-anchor > .simple-accordion-btn::after {
      opacity: 0;
      transform: rotate(0);
    }
  }
}

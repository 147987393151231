/* table */
.table {
  margin: 0 0 0.8rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  width: 100%;
}
.table th,
.table td {
  font-weight: normal;
}
.table td ul li {
  margin: 0 0 0 20px;
  list-style: outside disc;
}

/* bordered */
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(185,185,185,0.5);
}
.table-bordered th {
  background-color: rgba(186,186,186,0.5);
}

/* dashed */
.table-dashed th,
.table-dashed td {
  border-top: none;
  border-bottom: 1px dashed rgba(185,185,185,0.5);
}
.table-dashed tr:last-child th,
.table-dashed tr:last-child td {
  border-bottom: none;
}
.table-dashed td {
  border-left: 1px dashed rgba(185,185,185,0.5);
}

/* striped */
.table-striped tr:nth-of-type(odd) {
  background-color: rgba(186,186,186,0.5);
}


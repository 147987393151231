.entry-content {
  h2 {
    clear: both;
    margin: 1.8rem 0 0.8rem 0;
  }
  h3, .h3 {
    clear: both;
    margin: 1.8rem 0 0.8rem 0;
  }
  h4, .h4 {
    clear: both;
    margin: 1.2rem 0 0.8rem 0;
  }
  h5, .h5 {
    clear: both;
    margin: 1.2rem 0 0.8rem 0;
  }
  h6, .h6 {
    clear: both;
    margin: 1.2rem 0 0.8rem 0;
  }

  h3:first-child {
    margin-top: 0;
  }

  p {
    margin-bottom: 0.8rem;
  }
  p:last-child {
    margin-bottom: 0;
  }

  /* unordered list */
  ul {
    margin-bottom: 20px;
  }
  ul li {
    margin-left: 25px;
    list-style: outside disc;
  }

  ul li ul {
    margin-top: 10px;
    margin-bottom: 15px;
    list-style-type: none;
  }
  ul li ul li {
    list-style: outside circle;
  }

  /* ordered list */
  ol {
    margin-bottom: 20px;
  }
  ol li {
    margin-left: 25px;
    list-style: outside decimal;
  }
  ol li ol {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  /* definition list */
  dl {
    margin-bottom: 20px;
  }
  dt {
  }
  dd {
    margin-bottom: 0.5rem;
  }
}

.entry {
  &-footer {
    margin: 20px 0 0 0;
    padding: 10px;
    font-size: 11px;
  }

  &-meta {
    padding: 2px 0 0 0;
    text-align: right;
  }
}


.postie-image {
  float: left;
  display: inline;
  img {
    margin: 0 20px 20px 0;
  }
}

@media screen and (max-width: 768px) {
  margin: 0 auto 20px;
}

.pagination-wrap {
  text-align: center;
}
.pagination {
  display: inline-flex;
}
.page-item {
}
.page-link {
}
.page-link:focus,
.page-link:hover,
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  text-decoration: none;
}

.form {
}
.form-header {
  margin-bottom: 1.2rem;
}
.form-content {
}
.form-item {
  margin: 0;
  padding-top:    0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid rgba(186,186,186,0.5);
}
.form-item-title {
  font-weight: bold;
}
.form-item-title .required {
  padding: 0 0 0 2px;
  color: rgba(255,0,0,0.9);
}
.form-item-title .summary {
  font-size: 11px;
  font-weight: normal;
}
.form-btn-wrap {
  text-align: center;
  border-top: 1px solid rgba(186,186,186,0.5);
  padding: 10px;
}

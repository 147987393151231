@charset "utf-8";

/* ////////////////////////////////////////////
 *                 Reset
 * //////////////////////////////////////////// */

/* Reset
------------------------------------------*/
a,
input {
	outline: none;
}
p {
	margin: 0;
}
ul, ol, dl {
	margin: 0;
	padding: 0;
}
dt, dd {
	margin: 0;
}
li {
	list-style: none;
}
img {
	vertical-align: bottom;
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
}
em {
	font-style: italic;
}


/* Bootstrap Reset
------------------------------------------*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0;
	padding: 0;
	font-size: 100%;
	line-height: inherit;
}
caption {
	text-align: center;
	color: inherit;
	caption-side: top;
}
label {
	margin-bottom: 0;
}

.form-inline .form-control {
	max-width: 100%;
}

/* active color */
.form-control:focus {
	border-color: rgba(127,127,127,0.5);
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
	outline-color: rgba(127,127,127,0.5);
}

/* active時のcolorはopacityで調整する */
.btn-primary {
	opacity: 1;
}
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn-primary.active:focus,
.btn-primary:active:focus,
.btn-primary.active:hover,
.btn-primary:active:hover,
.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
	opacity: 0.8;
}

.btn-secondary {
	opacity: 1;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.btn-secondary.active:hover,
.btn-secondary:active:hover,
.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
	opacity: 0.8;
}

.btn-outline-primary {
	opacity: 1;
}
.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover,
.btn-outline-primary.active:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:hover,
.btn-outline-primary:active:hover,
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
	opacity: 0.8;
}

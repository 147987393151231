.bookmarks {
  .bookmarks-btn {
    float: left;
    display: inline;
    margin: 0 5px 0 0;
  }

  .twitter {
    margin: 0 5px 0 0;
  }
}

/* base */
.slick-slider {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}
.slick-track::before,
.slick-track::after {
  display: table;
  content: '';
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.slick-arrow.slick-hidden  {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  /* reset */
  cursor: pointer;
  padding: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  display: block;
  position: absolute;
  top: 50%;
  z-index: 10;
  width:  2rem;
  height: 2rem;
  margin-top: -1rem;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  /* reset */
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  opacity: 1;
}
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: .25;
}
.slick-prev::before,
.slick-next::before {
  display: block;
  width: 2rem;
  height: 2rem;
  text-align: center;

  /* fontawesome */
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  opacity: .75;
  color: white;
}
.slick-prev::before {
  content: '\f104';
}
.slick-next::before {
  content: '\f105';
}
[dir='rtl'] .slick-prev {
  right: 0;
  left: auto;
}
[dir='rtl'] .slick-prev::before {
  content: '\f105';
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 0;
}
[dir='rtl'] .slick-next::before {
  content: '\f104';
}


/* Dots */
.slick-dotted {
  margin-bottom: 30px;
}
.slick-dots {
  display: block;
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.slick-dots li button {
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  padding: 5px;
  cursor: pointer;

  /* reset */
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  text-align: center;
  color: #ccc;

  /* fontawesome */
  content: '\f111';
  font-size: 0.8rem;
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button:hover::before,
.slick-dots li button:focus::before,
.slick-dots li.slick-active button::before {
  color: #666;
}



/* slick samples
------------------------------------------*/
/* 画面幅100%のスライダー */
.slideshow-fullwidth {
  margin: 0 calc(-50vw + 490px);
}
.slideshow-fullwidth .slick-slide {
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .slideshow-fullwidth {
    margin: 0 auto;
  }
}


/* paginationをサムネイルにする */
.slideshow-thumbpager .slick-dots li {
  width: 20%;
  margin: 0;
}


/* ticker */
.slideshow-ticker .slick-slide {
  margin: 0 5px;
}

/* carousel */
.slideshow-carousel .slick-slide {
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
  .slideshow-carousel .slick-slide {
    margin: 0;
  }
}

/* carousel col2 */
.slideshow-carousel-col2 {
  margin: 0 -5px;
}
.slideshow-carousel-col2 .slick-slide {
  padding: 0 5px;
}

.template {
  margin: 0 0 20px 0;
}
.template h2, .template h3, .template h4, .template h5, .template h6 {
  margin-top: 0;
}
.template.row > div {
  margin-bottom: 10px;
}
.template-img-left {
  float: left;
  display: inline;
  margin-right:  20px;
  margin-bottom: 20px;
}
.template-img-right {
  float: right;
  display: inline;
  margin-left:   20px;
  margin-bottom: 20px;
}
.template-text {
  overflow: hidden;
}
.template-text-left {
  float: left;
  margin-right: 20px;
}
.template-text-right {
  float: right;
  margin-left: 20px;
}
.template-backpattern {
  padding: 2%;
  background: url(/images/common/template-backpattern-bg.png) repeat 0 0;
  border: #eaeaea 2px solid;
}

/* smartphone only */
@media screen and (max-width: 768px) {
  .template-img-left {
    float: none;
    display: block;
    margin-right:  0;
    margin-bottom: 20px;
  }
  .template-img-right {
    float: none;
    display: block;
    margin-left:   0;
    margin-bottom: 20px;
  }
  .template-text-left {
    float: none;
    margin-right:  0;
  }
  .template-text-right {
    float: none;
    margin-left:   0;
  }
}

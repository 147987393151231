.drawer-btn {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .fa {
    &::before {
      content: "\f0c9";
    }
    .is-drawer-open &::before {
      content: "\f00d";
    }
  }
}

.drawer-btn-bar {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #444;
  transition: .2s ease-out;

  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 0 0 -10px;

  &:nth-child(1) {
    transform: translateY(-6px);
  }
  &:nth-child(2) {}
  &:nth-child(3) {
    transform: translateY(6px);
  }

  .is-drawer-open & {
    &:nth-child(1) {
      transform: translateY(0px) rotate(45deg);
    }
    &:nth-child(2) {
      transform: translateX( 20px / 3 );
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translateY(0px) rotate(-45deg);
    }
  }
}

.drawer-bg {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background: rgba(255,255,255,0.8);

  .is-drawer-open & {
    display: block;
    animation-duration: 0.5s;
    animation-name: fade-in;
  }
}

.drawer-menu {
  display: none;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 100;
  background: rgba(255,255,255,0.9);

  .is-drawer-open & {
    display: block;
    animation-duration: 0.5s;
    animation-name: fade-in;
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

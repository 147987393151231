/* align */
.alignleft {
  float: left;
  margin: 0.5rem 1rem 0.5rem 0;
}
.alignright {
  float: right;
  margin: 0.5rem 0 0.5rem 1rem;
}
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.breadcrumb-wrap {
  display: none;
}
.breadcrumb {
  padding: 0;
  margin-bottom: 10px;
  background: none;
  font-size: 12px;
}
.breadcrumb-item + .breadcrumb-item:before {
  content: '>';
  color: inherit;
}
.breadcrumb-item.home:before {
  padding: 0 2px 0 4px;
  content: '\f015';
  font-weight: normal;
  font-size: 11px;
  font-family: 'Font Awesome 5 Free';
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.yu-gothic {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", sans-serif;
}
.hiragino-gothic {
  font-family: "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", sans-serif;
}
.meiryo {
  font-family: "メイリオ", Meiryo, sans-serif;
}
.ms-gothic {
  font-family: "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}
.yu-mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, serif;
}
.hiragino-mincho {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
}

.apple-system {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}
.helvetica {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
}
.arial {
  font-family: arial, helvetica, sans-serif;
}
.verdana {
  font-family: verdana, geneva, sans-serif;
}
.times {
  font-family: 'times new roman', times, serif;
}
.georgia {
  font-family: georgia, palatino, serif;
}
.book-antiqua {
  font-family: 'book antiqua', palatino, serif;
}
.courier-new {
  font-family: 'courier new', courier, monospace;
}

// 総称ファミリー
.sans-serif {
  font-family: sans-serif;
}
.serif {
  font-family: serif;
}
.monospace {
  font-family: monospace;
}
.fantasy {
  font-family: fantasy;
}
.cursive {
  font-family: cursive;
}

// ios, android非対応
.andale-mono {
  font-family: "Andale Mono", AndaleMono, monospace;
}
.arial-black {
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.comic-sans-ms {
  font-family: 'comic sans ms', sans-serif;
}
.impact {
  font-family: Impact, Charcoal, sans-serif;
}
.symbol {
  font-family: symbol;
}
.tahoma {
  font-family: tahoma, arial, helvetica, sans-serif;
}
.terminal {
  font-family: terminal, monaco, monospace;
}
.trebuchet-ms {
  font-family: 'trebuchet ms', geneva, sans-serif;
}
